import React from "react";
import SEO from "../components/utilities/SEO";
import Layout from "../components/layout/Layout";
import ContentTitle from "../components/content-title/ContentTitle";
import Col from "react-bootstrap/Col";
import ContentWrapper from "../components/content-wrapper/ContentWrapper";

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <ContentWrapper>
      <Col xs={12}>
        <ContentTitle title={"Kontakt"} />
        <div className="d-flex justify-content-center">
          <p className="mt-4">
            <strong>Ärinimi:</strong> ARMACOM OÜ <br /> <strong>Reg.NR:</strong>{" "}
            11289883 <br /> <strong>Telefon:</strong>{" "}
            <a href="tel:+37253023025">(+372) 53023025</a>
            <br /> <strong>E-mail:</strong>{" "}
            <a href="mailto: info@armacom.ee">info@armacom.ee</a>
          </p>
        </div>
      </Col>
    </ContentWrapper>
  </Layout>
);

export default ContactPage;
